import { Chart, registerables } from 'chart.js'
//import planetChartData from '../../assets/data/planet-data.js'
export default {
  props: {
    canvasId: {
      type: String,
      default: 'chart'
    },
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      config: {
        type: "bar",
        data: {
          labels: [],
          datasets: []
        },
        options: {
          responsive: true,
          lineTension: 1,
          scales: {
            y: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25
                }
              }
            ]
          },
          plugins:{
            legend: {
              display: false
            },
            tooltips: {
              enabled: true
            }
          }
        }
      },
    }
  },
  mounted() {
    this.config = {...this.config, ...this.chartData}
    Chart.register(...registerables);
    const ctx = document.getElementById(`${this.canvasId}`);
    // eslint-disable-next-line no-unused-vars
    const chart = new Chart(ctx, this.config);
  }
}