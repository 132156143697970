import KnChart from '../KnChart/KnChart.vue'

export default {
  components: { KnChart },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    keyGraph: {
      type: String,
      default: 'g0'
    },
    showText: {
      type: Boolean,
      default: true
    },
    showGraph: {
      type: Boolean,
      default: true
    },
    colsGraph: {
      type: [String, Number],
      default: "6"
    },
    bigMount: {
      type: [String, Number],
      default: '$0.00'
    },
    chartData: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    numCols() {
      return parseInt(this.colsGraph)
    }
  }
}